<template>
<div class="mainform">
    <div class="form">
        <el-form ref="form" class="content" :model="form" label-width="170px" :disabled="isReadonly">
            <el-form-item label="客户物料编号">{{form.materialCode}}
            </el-form-item>
            <el-form-item label="客户物料描述">{{form.materialDesc}}
            </el-form-item>
            <el-form-item label="物料类型">{{form.materialType}}
            </el-form-item>
            <el-form-item label="单位">{{form.unit}}
            </el-form-item>
            <el-form-item label="单位MAC数量">
            </el-form-item>
            <el-form-item label="单位WWN数量">
            </el-form-item>
            <el-form-item label="我的物料编号">
                <el-input v-model="form.supplierMaterialCode"></el-input>
            </el-form-item>
            <el-form-item label="我的物料描述">
                <el-input v-model="form.supplierMaterialDesc"></el-input>
            </el-form-item>
            <el-form-item label="LT交期">
                <el-input v-model="form.deliveryTime"></el-input>
            </el-form-item>
            <el-form-item label="是否贴条码">
                <el-radio v-model="form.isHasBarcode" label="是">是</el-radio>
                <el-radio v-model="form.isHasBarcode" label="否">否</el-radio>
            </el-form-item>
            <el-form-item label="最小批量">
                <el-input v-model="form.minBatch"></el-input>
            </el-form-item>
            <el-form-item label="ECN类别">
                <el-input v-model="form.ecnType"></el-input>
            </el-form-item>
            <el-form-item label="ECN变更描述">
                <el-input v-model="form.ecnUpdateDesc"></el-input>
            </el-form-item>
        </el-form>
    </div>
</div>
</template>

<script>
export default {
  name: 'ComMaterialBody',
  props: ['showData', 'isReadonly'],
  data: function () {
    return {
      form: this.showData
    }
  }
}
</script>

<style scoped lang="scss">
  @import '@/assets/css/elForm.scss';
  .mainform{
    background: none;
    padding: 0;
    .form{
      background: none;
      .content{
        padding: 0px 0;
      }
    }
  }
.mainform .form .el-form .el-form-item:last-child {
    margin-bottom: 0px;
}
</style>
